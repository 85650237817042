import { useNavigate } from "react-router-dom";
import Constants from "../../../shared/utils/Constants";
import PageContent from "shared/components/PageContent";
import Paper from "library/mui5/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GroupForm from "../../../library/Forms/GroupForm";
import { useInfoMessage } from "../../../hooks";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGroupsContext from "hooks/useGroupsContext";
import { useTranslation } from "react-i18next";

function CreateGroup() {
  const [, groupActions] = useGroupsContext();
  const athletes = useAthleteStore();
  const infoMessage = useInfoMessage({ type: "error" });
  const navigate = useNavigate();

  const handleGroupCreate = (group) => {
    return groupActions
      .create(group)
      .then((newGroup) => {
        navigate(`${Constants.routes.groups.route}/${newGroup.id}`);
      })
      .catch(infoMessage);
  };

  const { t } = useTranslation();

  return (
    <PageContent pageHeadingLink={Constants.routes.groups.route} pageHeading={Constants.routes.groups.title}>
      <Grid container justifyContent="center">
        <Grid item sm={10} md={6} lg={5}>
          <Paper>
            <Box p={3}>
              <GroupForm
                submitText={t("Group.All.addGroupFormSubmitText")}
                onSubmit={handleGroupCreate}
                athleteOptions={athletes}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default CreateGroup;
