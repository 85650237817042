import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ReportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 4.5C5.25 4.08579 5.58579 3.75 6 3.75H14.25C14.4489 3.75 14.6397 3.82902 14.7803 3.96967L18.5303 7.71967C18.671 7.86032 18.75 8.05109 18.75 8.25V19.5C18.75 19.9142 18.4142 20.25 18 20.25H6C5.58579 20.25 5.25 19.9142 5.25 19.5V4.5ZM6.75 5.25V18.75H17.25V9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V5.25H6.75ZM15 6.31066L16.1893 7.5H15V6.31066Z"
        fill="currentColor"
      />
      <path
        d="M9.15283 13.125V14.25H8.50684V11.0508H9.59668C9.91309 11.0508 10.165 11.1489 10.3525 11.3452C10.5415 11.5415 10.636 11.7964 10.636 12.1099C10.636 12.4233 10.543 12.6709 10.3569 12.8525C10.1709 13.0342 9.91309 13.125 9.5835 13.125H9.15283ZM9.15283 12.5867H9.59668C9.71973 12.5867 9.81494 12.5464 9.88232 12.4658C9.94971 12.3853 9.9834 12.2681 9.9834 12.1143C9.9834 11.9546 9.94897 11.8279 9.88013 11.7341C9.81128 11.6389 9.71899 11.5906 9.60327 11.5891H9.15283V12.5867Z"
        fill="currentColor"
      />
      <path
        d="M11.0688 14.25V11.0508H11.9148C12.2883 11.0508 12.5857 11.1694 12.8069 11.4067C13.0295 11.644 13.1431 11.9692 13.1475 12.3823V12.9009C13.1475 13.3213 13.0361 13.6516 12.8135 13.8918C12.5923 14.1306 12.2869 14.25 11.8972 14.25H11.0688ZM11.7148 11.5891V13.7139H11.9082C12.1235 13.7139 12.2751 13.6575 12.363 13.5447C12.4509 13.4304 12.4971 13.2341 12.5015 12.9558V12.3999C12.5015 12.1011 12.4597 11.8931 12.3762 11.7759C12.2927 11.6572 12.1506 11.595 11.95 11.5891H11.7148Z"
        fill="currentColor"
      />
      <path
        d="M15.2634 12.9448H14.2593V14.25H13.6133V11.0508H15.3843V11.5891H14.2593V12.4087H15.2634V12.9448Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default ReportIcon;
