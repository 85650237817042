import { Resource } from "i18next";
import Charting from "./Charting-en.json";
import Global from "./Global-en.json";
import ExerciseLibrary from "./ExerciseLibrary-en.json";
import Leaderboards from "./Leaderboards-en.json";
import Workouts from "./Workouts-en.json";
import Athlete from "./Athlete-en.json";
import Group from "./Group-en.json";
import Measures from "./Measures-en.translations.json";
import ExportData from "./ExportData-en.json";
import Dashboards from "pages/Dashboards/Dashboards.translations-en";
import NotFound from "pages/NotFound/NotFound.translations-en.json";

const en: Resource = {
  translation: Object.assign(
    {},
    Athlete,
    Charting,
    Dashboards,
    ExerciseLibrary,
    ExportData,
    Global,
    Group,
    Leaderboards,
    Measures,
    NotFound,
    Workouts
  ),
};

export default en;
