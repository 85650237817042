import { styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";

export const ReviewAthleteAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  fontSize: 24,
  height: theme.spacing(7),
  width: theme.spacing(7),
  marginRight: theme.spacing(2),
}));
