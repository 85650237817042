import App from "../App/App";
import UserProvider from "providers/UserProvider";
import MuiThemeProvider from "library/MuiThemeProvider";
import ScreenSizeToSmall from "pages/ScreenSizeToSmall";
import { LicenseInfo } from "@mui/x-license-pro";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE_KEY);

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
  debug: process.env.NODE_ENV === "development",
});

function Root() {
  return (
    <PostHogProvider client={posthog}>
      <MuiThemeProvider>
        <UserProvider>
          <App />
          <ScreenSizeToSmall />
        </UserProvider>
      </MuiThemeProvider>
    </PostHogProvider>
  );
}

export default Root;
