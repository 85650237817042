import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material";

const buttonActiveColor = "rgba(255, 255, 255, 0.15)";

export const MenuLabel = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export const DrawerMenuListItem = styled(ListItem, {
  shouldForwardProp: (props: string) => props !== "hasSecondary",
})<{
  hasSecondary: boolean;
}>(({ theme, hasSecondary }) => ({
  padding: hasSecondary ? theme.spacing(0.5, 0, 0.5, 2) : theme.spacing(1.2, 0, 1.2, 2),
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: buttonActiveColor,
  },
}));
