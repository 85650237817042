import Live from "pages/Live";
import AthleteHub from "components/AthleteHub/AthleteHub";
import CreateAthlete from "components/AthleteHub/CreateAthlete";
import EditAthlete from "components/AthleteHub/EditAthlete/EditAthlete";
import Groups from "pages/Groups";
import CreateGroup from "components/Groups/CreateGroup/CreateGroup";
import Group from "pages/Group";
import EditGroup from "components/Groups/EditGroup/EditGroup";
import Leaderboards from "pages/Leaderboards";
import CreateReport from "components/ReportGeneration/CreateReport/CreateReport";
import AthleteReport from "components/ReportGeneration/CreateReport/AthleteReport/AthleteReport";
import GroupReport from "components/ReportGeneration/CreateReport/GroupReport/GroupReport";
import DataExport from "pages/DataExport";
import Charting from "pages/Charting";
import ReportGeneration from "components/ReportGeneration/ReportGeneration";
import Workouts from "pages/Workouts";
import AthleteProfile from "pages/AthleteProfile";
import Settings from "pages/Settings";
import NotFound from "pages/NotFound";
import Constants from "shared/utils/Constants";
import SandBox from "components/SandBox";
import { Route, Routes } from "react-router-dom";
import useCanDo from "hooks/useCanDo";
import AthleteBulkUpload from "pages/AthleteBulkUpload";
import ExerciseLibrary from "pages/ExerciseLibrary";
import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";
import Dashboards from "pages/Dashboards";

interface AppRoutesProps {}

function AppRoutes(props: AppRoutesProps) {
  const canDo = useCanDo();
  const archivedCustomExerciseIds = useArchivedCustomExercises(true);

  const dashboardsEnabled = canDo("dashboard.any");

  return (
    <Routes>
      <Route path={Constants.routes.home.route} element={dashboardsEnabled ? <Dashboards /> : <Live />} />
      <Route path={[Constants.routes.settings.route, "*"].join("/")} element={<Settings />} />
      <Route path={Constants.routes.athletes.route} element={<AthleteHub />} />
      <Route path={Constants.routes.athletesAdd.route} element={<CreateAthlete />} />
      {canDo("athlete.bulkUpload") && (
        <>
          <Route path={Constants.routes.bulkUpload.route} element={<AthleteBulkUpload />} />
        </>
      )}
      <Route path={Constants.routes.athletesSingle.route} element={<AthleteProfile />} />
      <Route path={Constants.routes.athletesEdit.route} element={<EditAthlete />} />
      {canDo("group.any") && (
        <>
          <Route path={Constants.routes.groups.route} element={<Groups />} />
          <Route path={Constants.routes.groupsAdd.route} element={<CreateGroup />} />
          <Route path={Constants.routes.groupsSingle.route} element={<Group />} />
          <Route path={Constants.routes.groupsEdit.route} element={<EditGroup />} />
        </>
      )}
      <Route path={Constants.routes.leaderBoard.route} element={<Leaderboards />} />
      {canDo("report.any") && (
        <>
          <Route path={Constants.routes.reportGeneration.route} element={<ReportGeneration />} />
          <Route path={Constants.routes.createReport.route} element={<CreateReport />} />
          <Route
            path={Constants.routes.createAthleteReport.route}
            element={<AthleteReport archivedCustomExerciseIds={archivedCustomExerciseIds} />}
          />
          <Route
            path={Constants.routes.useEditAthleteReport.route}
            element={<AthleteReport archivedCustomExerciseIds={archivedCustomExerciseIds} />}
          />
          {canDo("group.any") && (
            <>
              <Route
                path={Constants.routes.createGroupReport.route}
                element={<GroupReport archivedCustomExerciseIds={archivedCustomExerciseIds} />}
              />
              <Route
                path={Constants.routes.useEditGroupReport.route}
                element={<GroupReport archivedCustomExerciseIds={archivedCustomExerciseIds} />}
              />
            </>
          )}
        </>
      )}
      {canDo("library.any") && (
        <Route path={`${Constants.routes.exerciseLibrary.route}/*`} element={<ExerciseLibrary />} />
      )}
      <Route path="/live" element={<Live />} />
      <Route path="/charting" element={<Charting />} />
      {canDo("dashboard.any") && <Route path="/dashboards/*" element={<Dashboards />} />}

      {canDo("export.any") && <Route path={Constants.routes.exportData.route} element={<DataExport />} />}
      {canDo("workout.any") && <Route path="workouts/*" element={<Workouts />} />}
      {process.env.NODE_ENV === "development" && <Route path="/sandbox" element={<SandBox />} />}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
