import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useEffect, useState } from "react";

function useMakeProfileImageURL(profileImagePath?: string | null): string | null {
  const organisation = useOrganisation();
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!profileImagePath) return setPhotoUrl(null);

    const organisationPath = `images/users/${organisation.id}`;

    (async () => {
      const storage = getStorage();

      const downloadUrl = await getDownloadURL(ref(storage, `${organisationPath}/${profileImagePath}`));

      setPhotoUrl(downloadUrl);
    })();
  }, [profileImagePath, organisation.id]);

  return photoUrl;
}

export default useMakeProfileImageURL;
