import { useMemo, useEffect, useState } from "react";
import DeprecatedGroupsContext from "../../contexts/GroupsContext";
import GroupService from "services/GroupService";
import GroupsStoreContext from "./GroupsStoreContext";
import GroupActionsContext from "./GroupActionsContext/GroupActionsContext";
import { firstBy } from "thenby";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";

function GroupsProvider(props) {
  const [groups, setGroups] = useState([]);
  const organisation = useOrganisation();

  const groupActions = useMemo(
    () => ({
      fetchAll: async () => {
        if (!organisation.featureConfig.groupsEnabled) return;

        const result = await GroupService.getAll();

        setGroups(result);
      },
      fetchById: async (id) => {
        const result = await GroupService.fetchById(id);

        setGroups((prevGroups) => [...prevGroups.filter((group) => group.id !== id), result]);

        return result;
      },
      delete: async (id) => {
        await GroupService.delete(id);

        setGroups((prevGroups) => prevGroups.filter((group) => group.id !== id));

        return;
      },
      create: async (group) => {
        const result = await GroupService.create(group);
        const newGroup = { ...result, ...group };

        setGroups((prevGroups) => [...prevGroups, newGroup]);

        return newGroup;
      },
      update: async (id, group) => {
        const result = await GroupService.update(id, group);
        const updatedGroup = { id, ...group, ...result };

        setGroups((prevGroups) => [...prevGroups.filter((group) => group.id !== id), updatedGroup]);

        return updatedGroup;
      },
    }),
    [organisation.featureConfig.groupsEnabled]
  );

  useEffect(() => {
    groupActions.fetchAll();
  }, [groupActions]);

  const groupContextValue = useMemo(
    () => [groups.slice(0).sort(firstBy("name", { direction: "asc", ignoreCase: true }).thenBy("id")), groupActions],
    [groupActions, groups]
  );

  return (
    <DeprecatedGroupsContext.Provider value={groupContextValue}>
      <GroupActionsContext.Provider value={groupActions}>
        <GroupsStoreContext.Provider value={groupContextValue[0]}>{props.children}</GroupsStoreContext.Provider>
      </GroupActionsContext.Provider>
    </DeprecatedGroupsContext.Provider>
  );
}

export default GroupsProvider;
