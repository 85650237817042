import { faker } from "@faker-js/faker";
import merge from "lodash/merge";
import { DashboardTimePeriod } from "types/Dashboard";
import {
  AthleteKpiNumerical,
  AthleteProgressChart,
  AthleteTargetGauge,
  CalculateBy,
  DashboardAthleteWidgetType,
  DashboardWidgetBase,
  DashboardWidgetItemMetadata,
} from "types/DashboardWidget";

class FakeDashboardWidget implements DashboardWidgetBase {
  id: string = faker.string.alphanumeric(20);
  items: DashboardWidgetItemMetadata[] = [];
  type: DashboardAthleteWidgetType = DashboardAthleteWidgetType.AthleteKpiNumerical;
  name: string | null = null;
  description?: string | null | undefined;
  calculateBy: CalculateBy = "Average";
  groupId: string | null = null;
  timePeriod: DashboardTimePeriod | null = null;
  showPb: boolean | null = false;

  constructor(props?: Partial<AthleteKpiNumerical | AthleteProgressChart | AthleteTargetGauge>) {
    merge(this, props);
  }
}
export default FakeDashboardWidget;
