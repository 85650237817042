import { useMemo } from "react";
import { DashboardOptions } from "./DashboardOptionsProvider.types";
import DashboardOptionsContext from "./contexts/DashboardOptionsContext";

interface DashboardOptionsProviderProps {
  children?: React.ReactNode;
}

function DashboardOptionsProvider(props: DashboardOptionsProviderProps) {
  const dashboardOptions = useMemo<DashboardOptions>(
    () => ({
      timePeriods: [
        { type: "Latest", relativePeriod: null },
        // { type: "SecondLatest", relativePeriod: null },
        { type: "Previous", relativePeriod: "P1D" },
        { type: "Previous", relativePeriod: "P7D" },
        { type: "Previous", relativePeriod: "P30D" },
        { type: "Previous", relativePeriod: "P90D" },
        { type: "Previous", relativePeriod: "P365D" },
      ],
    }),
    []
  );

  return <DashboardOptionsContext.Provider value={dashboardOptions}>{props.children}</DashboardOptionsContext.Provider>;
}

export default DashboardOptionsProvider;
