import InfoCard from "components/InfoCard";
import { t } from "i18next";
import ExportIcon from "library/Icons/menu/ExportIcon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Mui5 from "library/mui5";

export interface DataExportAthleteInfoCardProps {
  onClose(): void;
}

function DataExportAthleteInfoCard(props: DataExportAthleteInfoCardProps) {
  return (
    <InfoCard title={t("Export.pageTitle")} icon={<ExportIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("Export.infoCardDescription1")} {t("Export.infoCardDescription2")} {t("Export.infoCardDescription3")}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" p={2}>
          <Mui5.Button
            fullWidth={false}
            sx={{ width: 320 }}
            size="medium"
            color="primary"
            LinkComponent="a"
            // @ts-ignore
            rel="noopener noreferrer"
            // @ts-ignore
            target="_blank"
            href="https://25970650.hs-sites-eu1.com/knowledge/how-to-export-data-from-the-hub"
          >
            {t("moreInfoButtonText")}
          </Mui5.Button>
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default DataExportAthleteInfoCard;
