import Grid from "@mui/material/Grid";
import NoDataWrapper from "components/NoDataWrapper";
import { useRequest } from "hooks";
import useDefinedContext from "hooks/useDefinedContext";
import useWindowDimensions from "hooks/useWindowDimensions";
import ConfirmationDialog from "library/ConfirmationDialog";
import { useCallback, useMemo, useState } from "react";
import { Leaderboard, LeaderboardBase } from "types";
import LeaderboardControlsContext from "../contexts/LeaderboardControlsContext";
import useLeaderboardsContext from "../hooks/useLeaderboardsContext";
import LeaderboardEditable from "./LeaderboardEditable";
import { calculateLeaderboardSize } from "./LeaderboardTables.utils";
import NoLeaderboardsMessage from "./NoLeaderboardsMessage";
import { useTranslation } from "react-i18next";

interface LeaderboardTablesProps {}

function LeaderboardTables(props: LeaderboardTablesProps) {
  const { t } = useTranslation();
  const [leaderboards, leaderboardActions] = useLeaderboardsContext();
  const [showCreate, setShowCreate] = useDefinedContext(LeaderboardControlsContext);
  const [deletableLeaderboard, setDeletableLeaderboard] = useState<Leaderboard | false>(false);
  const saveLeaderboard = useRequest(leaderboardActions.update, true);
  const windowDimensions = useWindowDimensions();
  const deleteLeaderboard = useRequest(leaderboardActions.delete, true);
  const createLeaderboard = useRequest(leaderboardActions.create, true);

  const handleSaveCurried = useCallback(
    (id: string) => async (leaderboard: LeaderboardBase) => {
      await saveLeaderboard(id, leaderboard);
    },
    [saveLeaderboard]
  );

  const handleSetDeletableLeaderboardCurried = useCallback(
    (leaderboard: Leaderboard) => () => {
      setDeletableLeaderboard(leaderboard);
    },
    []
  );
  const handleDelete = useCallback(async () => {
    deletableLeaderboard && (await deleteLeaderboard(deletableLeaderboard.id));
  }, [deletableLeaderboard, deleteLeaderboard]);

  const handleHideCreate = useCallback(() => {
    setShowCreate(false);
  }, [setShowCreate]);

  const handleCreate = useCallback(
    async (leaderboard: LeaderboardBase) => {
      await createLeaderboard(leaderboard);
      setShowCreate(false);
    },
    [createLeaderboard, setShowCreate]
  );

  const leaderboardWithCreate = useMemo(() => {
    const concatedLeaderboards: (Leaderboard | undefined)[] = [...leaderboards];

    if (showCreate) {
      concatedLeaderboards.push(undefined);
    }

    return concatedLeaderboards;
  }, [leaderboards, showCreate]);

  const lbSize = calculateLeaderboardSize(leaderboardWithCreate.length, windowDimensions.width);

  return (
    <>
      <Grid container spacing={0} height="100%" width="100%" justifyContent="center" m="auto" position="relative">
        <NoDataWrapper
          hasData={Boolean(leaderboardWithCreate.length)}
          fallback={
            !showCreate && (
              <Grid item maxWidth={600}>
                <NoLeaderboardsMessage />
              </Grid>
            )
          }
        >
          {leaderboardWithCreate.map((leaderboard, leaderboardIndex) => (
            /**
             * @author Carden
             * The @prop key - `leaderboardIndex` is used here so that
             * the leaderboard holds its state when it transitions from
             * the create state to the edit state.
             *
             * Warning: This will break if the leaderboard is sorted.
             * Traditionally, this should be done with a unique ID.
             */
            <Grid item key={leaderboardIndex} xs={lbSize.xsWidth} height="100%" p={1}>
              <LeaderboardEditable
                leaderboard={leaderboard}
                onSubmit={leaderboard ? handleSaveCurried(leaderboard.id) : handleCreate}
                onDelete={leaderboard ? handleSetDeletableLeaderboardCurried(leaderboard) : handleHideCreate}
                configPanelOpen={typeof leaderboard === "undefined"}
              />
            </Grid>
          ))}
        </NoDataWrapper>
      </Grid>
      <ConfirmationDialog
        open={!!deletableLeaderboard}
        setOpen={setDeletableLeaderboard}
        onConfirm={handleDelete}
        color="error"
        title={`${deletableLeaderboard && deletableLeaderboard.name}`}
        body={t("Leaderboard.deleteConfirmText")}
        confirmText={t("deleteButtonText")}
        cancelText={t("cancelButtonText")}
      />
    </>
  );
}

export default LeaderboardTables;
