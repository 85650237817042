import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function GroupIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7.5C5.58579 7.5 5.25 7.83579 5.25 8.25C5.25 8.66421 5.58579 9 6 9C6.41421 9 6.75 8.66421 6.75 8.25C6.75 7.83579 6.41421 7.5 6 7.5ZM3.75 8.25C3.75 7.00736 4.75736 6 6 6C7.24264 6 8.25 7.00736 8.25 8.25C8.25 9.49264 7.24264 10.5 6 10.5C4.75736 10.5 3.75 9.49264 3.75 8.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.5C17.5858 7.5 17.25 7.83579 17.25 8.25C17.25 8.66421 17.5858 9 18 9C18.4142 9 18.75 8.66421 18.75 8.25C18.75 7.83579 18.4142 7.5 18 7.5ZM15.75 8.25C15.75 7.00736 16.7574 6 18 6C19.2426 6 20.25 7.00736 20.25 8.25C20.25 9.49264 19.2426 10.5 18 10.5C16.7574 10.5 15.75 9.49264 15.75 8.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32868 13.259C3.92786 13.5596 3.75 13.9177 3.75 14.25C3.75 14.6642 3.41421 15 3 15C2.58579 15 2.25 14.6642 2.25 14.25C2.25 13.3397 2.74372 12.5727 3.42868 12.059C4.11341 11.5455 5.02499 11.25 6 11.25C6.97501 11.25 7.88659 11.5455 8.57132 12.059C9.25628 12.5727 9.75 13.3397 9.75 14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25C8.25 13.9177 8.07214 13.5596 7.67132 13.259C7.27027 12.9582 6.68184 12.75 6 12.75C5.31816 12.75 4.72973 12.9582 4.32868 13.259Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3287 13.259C15.9279 13.5596 15.75 13.9177 15.75 14.25C15.75 14.6642 15.4142 15 15 15C14.5858 15 14.25 14.6642 14.25 14.25C14.25 13.3397 14.7437 12.5727 15.4287 12.059C16.1134 11.5455 17.025 11.25 18 11.25C18.975 11.25 19.8866 11.5455 20.5713 12.059C21.2563 12.5727 21.75 13.3397 21.75 14.25C21.75 14.6642 21.4142 15 21 15C20.5858 15 20.25 14.6642 20.25 14.25C20.25 13.9177 20.0721 13.5596 19.6713 13.259C19.2703 12.9582 18.6818 12.75 18 12.75C17.3182 12.75 16.7297 12.9582 16.3287 13.259Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.25C11.1716 8.25 10.5 8.92157 10.5 9.75C10.5 10.5784 11.1716 11.25 12 11.25C12.8284 11.25 13.5 10.5784 13.5 9.75C13.5 8.92157 12.8284 8.25 12 8.25ZM9 9.75C9 8.09315 10.3431 6.75 12 6.75C13.6569 6.75 15 8.09315 15 9.75C15 11.4069 13.6569 12.75 12 12.75C10.3431 12.75 9 11.4069 9 9.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72362 15.9586C7.91323 16.5375 7.5 17.2695 7.5 18C7.5 18.4142 7.16421 18.75 6.75 18.75C6.33579 18.75 6 18.4142 6 18C6 16.6594 6.76202 15.5164 7.85176 14.738C8.94294 13.9586 10.412 13.5 12 13.5C13.588 13.5 15.0571 13.9586 16.1482 14.738C17.238 15.5164 18 16.6594 18 18C18 18.4142 17.6642 18.75 17.25 18.75C16.8358 18.75 16.5 18.4142 16.5 18C16.5 17.2695 16.0868 16.5375 15.2764 15.9586C14.4674 15.3808 13.3115 15 12 15C10.6885 15 9.53256 15.3808 8.72362 15.9586Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default GroupIcon;
