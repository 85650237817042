import { faker } from "@faker-js/faker";
import merge from "lodash/merge";
import { DashboardWidgetItemMetadata } from "types/DashboardWidget";
import { MetadataType } from "types";

class FakeDashboardWidgetItem implements DashboardWidgetItemMetadata {
  id: string = faker.string.alphanumeric(20);
  field: string | null = null;
  targets: {
    default: number[];
  } | null = null;

  type: MetadataType = MetadataType.EXERCISE;

  constructor(props?: Partial<DashboardWidgetItemMetadata>) {
    merge(this, props);
  }
}
export default FakeDashboardWidgetItem;
