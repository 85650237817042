import SvgIcon from "@mui/material/SvgIcon";

interface ChartingIconProps {
  htmlColor: string;
}

function ChartingIcon(props: ChartingIconProps) {
  return (
    <SvgIcon>
      <path
        d="M4.5 4.5V19.5H19.5"
        stroke={props.htmlColor}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 10.5L10.2803 9.96967C9.98744 9.67678 9.51256 9.67678 9.21967 9.96967L9.75 10.5ZM12.75 13.5L12.2197 14.0303C12.5126 14.3232 12.9874 14.3232 13.2803 14.0303L12.75 13.5ZM19.2803 8.03033C19.5732 7.73744 19.5732 7.26256 19.2803 6.96967C18.9874 6.67678 18.5126 6.67678 18.2197 6.96967L19.2803 8.03033ZM5.03033 16.2803L10.2803 11.0303L9.21967 9.96967L3.96967 15.2197L5.03033 16.2803ZM9.21967 11.0303L12.2197 14.0303L13.2803 12.9697L10.2803 9.96967L9.21967 11.0303ZM13.2803 14.0303L19.2803 8.03033L18.2197 6.96967L12.2197 12.9697L13.2803 14.0303Z"
        fill={props.htmlColor}
      />
    </SvgIcon>
  );
}
export default ChartingIcon;
