import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function WorkoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 5.25C6.33579 5.25 6 5.58579 6 6V18.75C6 19.1642 6.33579 19.5 6.75 19.5H17.25C17.6642 19.5 18 19.1642 18 18.75V6C18 5.58579 17.6642 5.25 17.25 5.25H15V3.75H17.25C18.4926 3.75 19.5 4.75736 19.5 6V18.75C19.5 19.9926 18.4926 21 17.25 21H6.75C5.50736 21 4.5 19.9926 4.5 18.75V6C4.5 4.75736 5.50736 3.75 6.75 3.75H9V5.25H6.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 4.125C8.25 3.50368 8.75368 3 9.375 3H14.625C15.2463 3 15.75 3.50368 15.75 4.125V5.625C15.75 6.24632 15.2463 6.75 14.625 6.75H9.375C8.75368 6.75 8.25 6.24632 8.25 5.625V4.125ZM9.75 4.5V5.25H14.25V4.5H9.75Z"
        fill="currentColor"
      />
      <rect x="9.75" y="9" width="6" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="7.5" y="9" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="9.75" y="12" width="6" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="7.5" y="12" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="9.75" y="15" width="6" height="1.5" rx="0.75" fill="currentColor" />
      <rect x="7.5" y="15" width="1.5" height="1.5" rx="0.75" fill="currentColor" />
    </SvgIcon>
  );
}
export default WorkoutIcon;
