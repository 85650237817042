import { faker } from "@faker-js/faker";
import merge from "lodash/merge";
import { DashboardTile, DashboardTileLayout } from "types/Dashboard";
import {
  AthleteKpiNumerical,
  AthleteProgressChart,
  AthleteTargetGauge,
  DashboardAthleteWidgetType,
} from "types/DashboardWidget";

class FakeDashboardTile implements DashboardTile {
  id: string = faker.string.alphanumeric(20);
  colour: string = "";
  description: string = "";
  athleteIds: string[] = [];
  layout: DashboardTileLayout = { x: 0, y: 0, width: 3 };
  widget: AthleteKpiNumerical | AthleteProgressChart | AthleteTargetGauge = {
    items: [],
    type: DashboardAthleteWidgetType.AthleteKpiNumerical,
    id: "",
    name: null,
    calculateBy: "Average",
    groupId: null,
    timePeriod: null,
    showPb: null,
  };

  constructor(props?: Partial<DashboardTile>) {
    merge(this, props);
  }
}
export default FakeDashboardTile;
