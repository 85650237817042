import amplitude from "amplitude-js";
import UserService from "../UserService";
import moment from "moment";
import { PROPERTIES } from "./AnalyticsService.config";

class AnalyticsService {
  static APPLICATION = "HUB_V1_WEB";

  static PROPERTIES = PROPERTIES;

  static EVENTS = {
    ATHLETE_REPORT_GENERATION: "hub:reports:athleteReportGenerate",
    GROUP_REPORT_GENERATION: "hub:reports:groupReportGenerate",
    ATHLETE_DATA_EXPORT: "hub:athletes:dataExport",

    HUB_LIVE_VIEW: "hub:live:viewPage",
    HUB_LIVE_FILTER: "hub:live:filter", // PROPERTIES: filterGroupCount, filterAthleteCount
    HUB_LIVE_TOGGLE_PERIOD: "hub:live:togglePeriod", // PROPERTIES: period,
    HUB_LIVE_SET_LEADERBOARD_EXERCISE: "hub:live:setLeaderboardExercise", // PROPERTIES: exerciseId, eventClickLocation
    HUB_LIVE_ATHLETE_PROFILE_CHART_EXERCISE: "hub:live:athleteProfileChartExercise", // PROPERTIES: exerciseId, eventClickLocation

    ATHLETES_VIEW_ALL: "hub:athletes:viewAll",
    ATHLETES_VIEW_PROFILE: "hub:athletes:viewProfile",
    ATHLETE_PROFILE_VIEW_DATA: "hub:athleteProfile:viewChart",
    ATHLETE_PROFILE_VIEW_1RM_MODAL: "hub:athleteProfile:view1RMModal",
    GROUPS_VIEW_ALL: "hub:groups:viewAll",
    GROUPS_VIEW_ONE: "hub:groups:viewOne",
    GROUP_OVERVIEW_VIEW_DATA: "hub:groupOverview:viewChart",
    LEADERBOARDS_VIEW_ALL: "hub:leaderboards:viewAll",
    REPORTS_VIEW_ALL: "hub:reports:viewAll",
    WORKOUTS_VIEW_ALL: "hub:workouts:viewAll",
    WORKOUTS_OVERVIEW_VIEW: "hub:workouts:viewWorkoutOverview",
    WORKOUTS_LABEL_ASSIGN: "hub:workouts:labelAssign", // PROPERTIES: workoutsCount
    WORKOUTS_LABEL_UNASSIGN: "hub:workouts:labelUnassign", // PROPERTIES: workoutsCount
    WORKOUTS_LABEL_FILTER_CLOSE: "hub:workouts:LabelFilterClose", // PROPERTIES: workoutsLabelFilterCount

    CUSTOM_EXERCISE_CREATE: "hub:customExercises:create", // PROPERTIES: derivedFromExerciseId, isWeighted, isRepBased, isBilateral, location, isMigration

    CHARTING_V3_VIEW_PAGE: "hub:chartingV3:viewPage",
    CHARTING_V3_VIEW_DATA: "hub:chartingV3:viewData", // PROPERTIES": measureId, sourceCount, measureCount, period, measureType
    CHARTING_V3_VIEW_1RM_LINE: "hub:chartingV3:view1RMLine",

    INFO_CARD_VIEW: "hub:infoCard:view", // PROPERTIES: page

    HUB_GLOBAL_SEARCH: "hub:globalSearch:click", // PROPERTIES: section
    HUB_FAB_OPEN: "hub:fab:open",
    HUB_FAB_ACTION: "hub:fab:action", // PROPERTIES: actionName
  };

  static __instance;

  static get instance() {
    if (typeof AnalyticsService.__instance === "undefined") {
      AnalyticsService.init();
    }

    return AnalyticsService.__instance;
  }

  static async init() {
    if (!process.env.REACT_APP_AMPLITUDE_API_KEY) {
      return;
    }

    if (typeof AnalyticsService.__instance !== "undefined") {
      throw new Error("AnalyticsService has already been initialized");
    }

    AnalyticsService.__instance = amplitude.getInstance();
    AnalyticsService.__instance.init(process.env.REACT_APP_AMPLITUDE_API_KEY);

    await AnalyticsService.setUserProperties();
  }

  static async setUserProperties() {
    const organisationId = await UserService.getOrganisationId();
    const { uid: userId } = UserService.currentUser;

    const now = moment().toISOString();

    AnalyticsService.instance.setUserId(userId);

    const identify = new AnalyticsService.instance.Identify()
      .setOnce(AnalyticsService.PROPERTIES.USER_PROPERTY_ORGANISATION_ID, organisationId)
      .setOnce(AnalyticsService.PROPERTIES.USER_PROPERTY_FIRST_SEEN, now)
      .set(AnalyticsService.PROPERTIES.USER_PROPERTY_LAST_SEEN, now);

    AnalyticsService.instance.identify(identify);
  }

  static prepareEventProperties(otherEventProperties = {}) {
    const { EVENT_PROPERTY_APPLICATION } = AnalyticsService.PROPERTIES;

    return { [EVENT_PROPERTY_APPLICATION]: AnalyticsService.APPLICATION, ...otherEventProperties };
  }

  static clear() {
    AnalyticsService.__instance?.clearUserProperties();
    AnalyticsService.__instance = undefined;
  }

  static async logEvent(eventName, eventProperties) {
    if (!AnalyticsService.__instance) {
      /**
       * @description
       * Allows time for amplitude init to complete */
      await new Promise((resolve) => setTimeout(resolve, 200));
    }

    if (AnalyticsService.__instance) {
      AnalyticsService.instance.logEvent(eventName, AnalyticsService.prepareEventProperties(eventProperties));
    }

    if (process.env.REACT_APP_DEBUG_ANALYTICS) {
      console.log(
        JSON.stringify(
          {
            "[EVENT]:": eventName,
            ...AnalyticsService.prepareEventProperties(eventProperties),
            analyticsInitialized: Boolean(AnalyticsService.__instance),
          },
          null,
          2
        )
      );
    }
  }
}
export default AnalyticsService;
