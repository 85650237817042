import Constants from "shared/utils/Constants";
import BoltIcon from "@mui/icons-material/OfflineBoltOutlined";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import AthleteIcon from "library/Icons/menu/AthleteIcon";
import ChartingIcon from "library/Icons/menu/ChartingIcon";
import ExportIcon from "library/Icons/menu/ExportIcon";
import GroupIcon from "library/Icons/menu/GroupIcon";
import DumbellIcon from "library/Icons/menu/DumbellIcon";
import LeaderboardIcon from "library/Icons/menu/LeaderboardIcon";
import ReportIcon from "library/Icons/menu/ReportIcon";
import WorkoutIcon from "library/Icons/menu/WorkoutIcon";
import SettingsIcon from "library/Icons/menu/SettingsIcon";
import useCanDo from "hooks/useCanDo";
import { useMemo } from "react";
import i18n from "config/i18n";

export const useDrawerMenuItems = () => {
  const canDo = useCanDo();
  return useMemo(
    () =>
      [
        canDo("dashboard.any") && {
          route: "/dashboards",
          title: "Dashboards",
          icon: <DashboardOutlined fontSize="medium" htmlColor={"#FFF"} sx={{ ml: 0 }} />,
        },
        {
          route: "/live",
          title: "Live",
          icon: <BoltIcon htmlColor={"#FFF"} />,
        },
        {
          route: Constants.routes.athletes.route,
          title: Constants.routes.athletes.title,
          icon: <AthleteIcon htmlColor={"#FFF"} />,
        },
        {
          route: "/charting",
          title: "Charting",
          icon: <ChartingIcon htmlColor={"#FFF"} />,
        },
        canDo("group.any") && {
          route: Constants.routes.groups.route,
          title: Constants.routes.groups.title,
          icon: <GroupIcon htmlColor={"#FFF"} />,
        },
        {
          route: Constants.routes.leaderBoard.route,
          title: Constants.routes.leaderBoard.title,
          icon: <LeaderboardIcon htmlColor={"#FFF"} />,
        },
        canDo("report.any") && {
          route: Constants.routes.reportGeneration.route,
          title: Constants.routes.reportGeneration.title,
          icon: <ReportIcon htmlColor={"#FFF"} />,
        },
        canDo("library.any") && {
          route: Constants.routes.exerciseLibrary.route,
          title: i18n.t("Library.mainMenuTitle"),
          icon: <DumbellIcon htmlColor={"#FFF"} />,
        },
        canDo("workout.any") && {
          route: "/workouts",
          title: "Workouts",
          icon: <WorkoutIcon htmlColor={"#FFF"} />,
        },
        canDo("export.any") && {
          route: Constants.routes.exportData.route,
          title: Constants.routes.exportData.title,
          icon: <ExportIcon htmlColor={"#FFF"} />,
        },
        {
          route: Constants.routes.settings.route,
          title: Constants.routes.settings.title,
          icon: <SettingsIcon htmlColor={"#FFF"} />,
        },
      ].filter(Boolean),
    [canDo]
  );
};
