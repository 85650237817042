export const PROPERTIES = {
  EVENT_PROPERTY_APPLICATION: "application",
  USER_PROPERTY_ORGANISATION_ID: "organisationId",
  USER_PROPERTY_FIRST_SEEN: "hubFirstSeen",
  USER_PROPERTY_LAST_SEEN: "hubLastSeen",
  CHART_TYPE: "chartType",
  VISUALIZATION_TYPE: "visType",
  EXERCISE_ID: "exerciseId", // String
  EXERCISE_CATEGORY: "exerciseCategory", // String
  METRIC_FIELD: "metricField", // String
  PERIOD: "period", // day, week
  FILTER_GROUP_COUNT: "filterGroupCount", // Number
  FILTER_ATHLETE_COUNT: "filterAthleteCount", // Number
  EVENT_CLICK_LOCATION: "eventClickLocation", // leaderboardExerciseSelector, liveActivityFeed

  CHARTING_MEASURE_COUNT: "measureCount",
  CHARTING_SOURCE_COUNT: "sourceCount",
  CHARTING_MEASURE_ID: "measureId",
  CHARTING_MEASURE_TYPE: "measureType",

  WORKOUTS_COUNT: "workoutsCount", // Number
  WORKOUTS_LABEL_ASSIGN_FLAG: "workoutsLabelAssignFlag", // Boolean
  WORKOUTS_LABEL_FILTERS_COUNT: "workoutsLabelFiltersCount", // Number

  CUSTOM_EXERCISE_DERIVED_FROM_ID: "derivedFromExerciseId",
  CUSTOM_EXERCISE_IS_WEIGHTED: "isWeighted",
  CUSTOM_EXERCISE_IS_REP_BASED: "isRepBased",
  CUSTOM_EXERCISE_IS_BILATERAL: "isBilateral",
  CUSTOM_EXERCISE_IS_MIGRATION: "isMigration",
  CUSTOM_EXERCISE_LOCATION: "location",
  PAGE: "page",
} as const;
