import { styled } from "@mui/material";
import MuiAvatar from "@mui/material/Avatar";
import v2Theme from "library/MuiThemeProvider/theme";

const Avatar = styled(MuiAvatar, { shouldForwardProp: (key: string) => !["size"].includes(key) })<{ size?: "small" }>(
  ({ theme, size }) => ({
    backgroundColor: v2Theme.palette.grey[100],
    color: v2Theme.palette.grey[20],
    height: theme.spacing(size === "small" ? 2 : 4),
    width: theme.spacing(size === "small" ? 2 : 4),
    fontSize: size === "small" ? "8px" : theme.typography.body1.fontSize,
    fontWeight: 400,
    textAlign: "center",
    "&.MuiAvatar-root": {
      lineHeight: "23px",
    },
  })
);
export default Avatar;
