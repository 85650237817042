import DashboardsHome from "./DashboardsHome/DashboardsHome.translations-en.json";
import DashboardFilters from "pages/Dashboards/DashboardsHome/DashboardFilters/DashboardFilters.translations-en.json";
import DashboardSelectorsPermissionsToggle from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsPermissionsToggle/DashboardSelectorsPermissionsToggle.translations-en.json";
import DashboardSelectorsMoreMenu from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardSelectorsMoreMenu.translations-en.json";
import DashboardConfirmDeleteDialog from "pages/Dashboards/DashboardsHome/DashboardSelectors/DashboardSelectorsMoreMenu/DashboardConfirmDeleteDialog/DashboardConfirmDeleteDialog.translations-en.json";
import DashboardNoDataWrapper from "./providers/DashboardProvider/DashboardNoDataWrapper/DashboardNoDataWrapper.translations-en.json";
import DashboardEmptyWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/DashboardEmptyWidget.translations-en.json";
import AddAthleteWidgetDialog from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddAthleteWidgetDialog/AddAthleteWidgetDialog.translations-en.json";
import AthleteProgressChartWidget from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/AthleteProgressChartWidget.translations-en.json";
import WidgetTimeRange from "./DashboardsHome/DashboardWidgetFactory/components/WidgetTimeRange/WidgetTimeRange.translations-en.json";
import Widgets from "./DashboardsHome/DashboardWidgetFactory/Widgets/Widgets.translations-en.json";
import AddAthleteWidgetSelectWidget from "./DashboardsHome/DashboardWidgetFactory/DashboardEmptyWidget/AddAthleteWidgetDialog/AddAthleteWidgetSelectWidget/AddAthleteWidgetSelectWidget.translations-en.json";
import DashboardDuplicateToEditBar from "./DashboardsHome/DashboardDuplicateToEditBar/DashboardDuplicateToEditBar.translations-en.json";
import TooltipProgressTitle from "./DashboardsHome/DashboardWidgetFactory/components/TooltipProgressTitle.translations-en.json";
import LegendPersonalBest from "./DashboardsHome/DashboardWidgetFactory/Widgets/AthleteWidgets/AthleteProgressChartWidget/components/LegendPersonalBest/LegendPersonalBest.translations-en.json";

const DashboardsTranslationsEn = Object.assign(
  {
    Dashboards: {
      loadingMessage: "Loading Dashboards...",
    },
  },
  AddAthleteWidgetDialog,
  AddAthleteWidgetSelectWidget,
  DashboardsHome,
  DashboardConfirmDeleteDialog,
  DashboardDuplicateToEditBar,
  DashboardFilters,
  DashboardEmptyWidget,
  DashboardNoDataWrapper,
  DashboardSelectorsMoreMenu,
  DashboardSelectorsPermissionsToggle,
  Widgets,
  WidgetTimeRange,
  AthleteProgressChartWidget,
  TooltipProgressTitle,
  LegendPersonalBest
);

export default DashboardsTranslationsEn;
