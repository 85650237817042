import http from "../../axiosSetup";

class GroupService {
  static getAll() {
    return http.get("/groups").then((response) => response.data);
  }

  static fetchById(id) {
    return http.get(`/groups/${id}`).then((response) => response.data);
  }

  static delete(id) {
    return http.delete(`/groups/${id}`).then((response) => response.data);
  }

  static create(group) {
    return http.post("/groups", group).then((response) => response.data);
  }

  static update(id, group) {
    return http.put(`/groups/${id}`, group).then((response) => response.data);
  }
}
export default GroupService;
