import { useState } from "react";

function useMuiMenu(): [
  null | HTMLElement,
  { handleOpen(event: React.MouseEvent<HTMLButtonElement>): void; handleClose(): void },
] {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return [anchorEl, { handleOpen, handleClose }];
}
export default useMuiMenu;
