import http from "axiosSetup";
import { DashboardTimePeriodType, DashboardType, type Dashboard } from "types/Dashboard";
import { DashboardAthleteWidgetType } from "types/DashboardWidget";
import { MetadataType } from "types";
import { DashboardActions } from "pages/Dashboards/providers/DashboardProvider";
import FakeDashboardTile from "fake/FakeDashboardTile";
import FakeDashboardWidget from "fake/FakeDashboardWidget";
import FakeDashboardWidgetItem from "fake/FakeDashboardWidgetItem";

const targetGauges = [
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteTargetGauge,
      items: [
        new FakeDashboardWidgetItem({
          type: MetadataType.ANTHROPOMETRIC,
          id: "weight",
          field: null,
          targets: {
            default: [82],
          },
        }),
      ],
      timePeriod: null,
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteTargetGauge,
      items: [
        new FakeDashboardWidgetItem({
          type: MetadataType.WELLNESS,
          id: "SLEEP",
          field: null,
          targets: {
            default: [2.6],
          },
        }),
      ],
      timePeriod: null && {
        // Kept for mocking purposes
        type: DashboardTimePeriodType.Previous,
        startDate: "2024-01-01",
        endDate: "2024-12-31",
        relativePeriod: "P30D",
      },
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteTargetGauge,
      items: [
        new FakeDashboardWidgetItem({
          type: MetadataType.EXERCISE,
          id: "SHOULDER_ROTATION_EXTERNAL",
          field: "rangeOfMotion",
          targets: {
            default: [150],
          },
        }),
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteTargetGauge,
      items: [
        new FakeDashboardWidgetItem({
          type: MetadataType.EXERCISE,
          id: "BARBELL_BACK_SQUAT",
          field: "meanPower",
          targets: {
            default: [1000],
          },
        }),
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteTargetGauge,
      items: [
        new FakeDashboardWidgetItem({
          type: MetadataType.EXERCISE,
          id: "BILATERAL_CMJ",
          field: "jumpHeight",
          targets: {
            default: [0.9],
          },
        }),
      ],
    }),
  }),
];

const progressCharts = [
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteProgressChart,
      items: [
        {
          type: MetadataType.EXERCISE,
          id: "SHOULDER_ROTATION_EXTERNAL",
          field: "rangeOfMotion",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteProgressChart,
      items: [
        // {
        //   type: MetadataType.EXERCISE,
        //   id: "hXzYhXbyxhZXSOiXcvkx",
        //   field: "meanPower",
        //   targets: null,
        // },
        {
          type: MetadataType.EXERCISE,
          id: "BILATERAL_CMJ",
          field: "jumpHeight",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteProgressChart,
      items: [
        {
          type: MetadataType.WELLNESS,
          id: "SLEEP",
          field: null,
          targets: null,
        },
        {
          type: MetadataType.EXERCISE,
          id: "BARBELL_BACK_SQUAT",
          field: "meanPower",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteProgressChart,
      items: [
        {
          type: MetadataType.ANTHROPOMETRIC,
          id: "weight",
          field: null,
          targets: null,
        },
        {
          type: MetadataType.EXERCISE,
          id: "BILATERAL_CMJ",
          field: "jumpHeight",
          targets: null,
        },
      ],
    }),
  }),
];

const kpiNumerical = [
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteKpiNumerical,
      items: [
        {
          type: MetadataType.EXERCISE,
          id: "SHOULDER_ROTATION_EXTERNAL",
          field: "rangeOfMotion",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteKpiNumerical,
      items: [
        {
          type: MetadataType.EXERCISE,
          id: "UNILATERAL_CMJ_ARMS_FREE",
          field: "jumpHeight",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteKpiNumerical,
      items: [
        {
          type: MetadataType.EXERCISE,
          id: "BILATERAL_CMJ",
          field: "jumpHeight",
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteKpiNumerical,
      items: [
        {
          type: MetadataType.ANTHROPOMETRIC,
          id: "weight",
          field: null,
          targets: null,
        },
      ],
    }),
  }),
  new FakeDashboardTile({
    widget: new FakeDashboardWidget({
      type: DashboardAthleteWidgetType.AthleteKpiNumerical,
      items: [
        {
          type: MetadataType.WELLNESS,
          id: "SLEEP",
          field: null,
          targets: null,
        },
      ],
    }),
  }),
];

const mockDashboard: Dashboard = {
  id: "MOCK_DASHBOARD_ID",
  name: "Hard Coded Dashboard",
  creatorId: "",
  organisationId: "",
  createdAt: "2024-01-01T00:00:00.000Z",
  updatedAt: "2024-01-01T00:00:00.000Z",
  isGlobal: false,
  type: DashboardType.Athlete,
  athleteId: "CcSGxeLI3e7PMEOTw3XX", // SamAthleteId,
  // athleteId: "FafrdpTqvyaHgxaQJkvg", // StephenAthleteId,
  groupId: null,
  // excludeAthleteIds: [], // Not implemented yet
  timePeriod: {
    type: DashboardTimePeriodType.Previous,
    relativePeriod: "P365D",
  },
  tiles: [
    //
    ...progressCharts,
    ...targetGauges,
    ...kpiNumerical,
  ],
};

class DashboardService {
  static delete: DashboardActions["deleteOne"] = (dashboard): Promise<{ id: string }> => {
    return http.delete<{ id: string }>(`/dashboards/${dashboard.id}`).then((response) => response.data);
  };

  static create: DashboardActions["addOne"] = (newDashboard): Promise<{ id: string }> => {
    return http.post<{ id: string }>(`/dashboards`, newDashboard).then((response) => response.data);
  };

  static save(dashboard: Dashboard): Promise<{ id: string }> {
    return http
      .put<{ id: string }>(`/dashboards/${dashboard.id}`, JSON.parse(JSON.stringify(dashboard)))
      .then((response) => response.data);
  }

  static async updateView(dashboard: Pick<Dashboard, "id" | "isGlobal">): Promise<void> {
    return http
      .patch(`/dashboards/${dashboard.id}/global`, { isGlobal: dashboard.isGlobal })
      .then((response) => response.data);
  }

  static async getAll(): Promise<Dashboard[]> {
    const result = await http.get<Dashboard[]>("/dashboards").then((response) => response.data);
    /**
     * TODO: Remove me before release
     */
    return (result.length ? result : [mockDashboard]).map(DashboardService.TEMP_generateTileLayouts);
  }

  static async get(id: string): Promise<Dashboard> {
    const result = await http.get<Dashboard>(`/dashboards/${id}`).then((response) => response.data);
    return DashboardService.TEMP_generateTileLayouts(result);
  }

  private static TEMP_generateTileLayouts(dashboard: Dashboard): Dashboard {
    const withLayout = {
      ...dashboard,
      tiles: (dashboard.tiles || []).map((tile, tileIndex) => ({
        ...tile,
        layout: tile.layout || {
          x: tileIndex % 2 === 0 ? 0 : 3,
          y: Math.floor(tileIndex / 2),
          width: 3,
        },
      })),
    };
    return withLayout;
  }
}

export default DashboardService;
