import type { DateTime } from "types";
import type { DashboardWidget } from "./DashboardWidget";

export enum DashboardTimePeriodType {
  Latest = "Latest",
  SecondLatest = "SecondLatest",
  Previous = "Previous",
}

export const RelativeTimePeriod = {
  Latest: "Latest",
  /**
   * @type ISO8601 Duration
   * {@link | https://www.digi.com/resources/documentation/digidocs/90001488-13/reference/r_iso_8601_duration_format.htm}
   */
  P1D: "P1D",
  P7D: "P7D",
  P30D: "P30D",
  P90D: "P90D",
  P365D: "P365D",
} as const;

export type RelativeTimePeriodType = StringAutocomplete<(typeof RelativeTimePeriod)[keyof typeof RelativeTimePeriod]>;

export interface DashboardTimePeriod {
  type: `${DashboardTimePeriodType}`;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  relativePeriod: RelativeTimePeriodType | null;
}

export enum DashboardType {
  Athlete = "Athlete",
  Group = "Group",
}

export interface DashboardBase {
  name: string | null; // Default to `Exercise Name - Metric Name`
  creatorId: string;
  isGlobal: boolean;
  type: `${DashboardType}`;
  // Who
  athleteId: string;
  groupId: string | null;
  // excludeAthleteIds: string[];
  // When
  timePeriod: DashboardTimePeriod;
  tiles: DashboardTile[];
  createdAt: string;
  updatedAt: string;
}

export interface DashboardTileLayout {
  x: number;
  y: number;
  width: number;
  // height: number;
}

export interface DashboardTile {
  id: string;
  layout: DashboardTileLayout;
  widget: DashboardWidget;
}

export interface Dashboard extends DashboardBase {
  id: string;
  organisationId: string;
}
