import MetadataProvider from "providers/MetadataProvider";
import InfoMessageProvider from "providers/InfoMessageProvider/InfoMessageProvider";
import AthletesProvider from "providers/AthletesProvider";
import GroupsProvider from "providers/GroupsProvider";
import { BrowserRouter as Router } from "react-router-dom";
import GoogleAnalytics from "shared/components/GoogleAnalytics";
import AthleteMonitoringStatusesProvider from "providers/AthleteMonitoringStatusesProvider";
import RAGSettingsProvider from "providers/RAGSettingsProvider";
import AmplitudeAnalytics from "components/AmplitudeAnalytics";
import ReportsProvider from "providers/ReportsProvider";
import RemoteConfigProvider from "providers/RemoteConfigProvider";
import DateTimePickers from "library/mui5/DateTimePickers";
import ExercisesProvider from "providers/ExercisesProvider";
import WellnessMetadataProvider from "providers/WellnessMetadataProvider";
import AnthropometricMetadataProvider from "providers/AnthropometricMetadataProvider";
import PostHogAnalyticsProvider from "providers/PostHogAnalyticsProvider";

function GlobalConfig({ children }) {
  return (
    <Router>
      <RemoteConfigProvider>
        <AmplitudeAnalytics />
        <GoogleAnalytics />
        <PostHogAnalyticsProvider />
        <InfoMessageProvider>
          <ExercisesProvider>
            <WellnessMetadataProvider>
              <AnthropometricMetadataProvider>
                <MetadataProvider>
                  <AthletesProvider>
                    <GroupsProvider>
                      <AthleteMonitoringStatusesProvider>
                        <>
                          <RAGSettingsProvider>
                            <ReportsProvider>
                              <DateTimePickers.LocalizationProvider>{children}</DateTimePickers.LocalizationProvider>
                            </ReportsProvider>
                          </RAGSettingsProvider>
                        </>
                      </AthleteMonitoringStatusesProvider>
                    </GroupsProvider>
                  </AthletesProvider>
                </MetadataProvider>
              </AnthropometricMetadataProvider>
            </WellnessMetadataProvider>
          </ExercisesProvider>
        </InfoMessageProvider>
      </RemoteConfigProvider>
    </Router>
  );
}
export default GlobalConfig;
