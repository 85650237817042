import { MetadataType } from "types";
import http from "../../axiosSetup";
import moment from "moment";
import AthleteService from "services/AthleteService";

class MeasurementService {
  static getById(id) {
    return http.get(`measurements/${id}`).then((response) => response.data);
  }

  static deleteById(id) {
    return http.delete(`measurements/exercise/${id}`).then((response) => response.data);
  }

  static getByAthleteId(athleteId, params) {
    return http.get(`/measurements/athlete/${athleteId}/exercise`, { params }).then((response) => response.data);
  }

  static getCompletedWorkout(completedWorkoutId) {
    return http.get(`/measurements/completed-workout/${completedWorkoutId}`).then((response) => response.data);
  }

  static deserializeFirestoreDoc(doc) {
    return {
      id: doc.id,
      ...doc.data(),
      completedDate: moment(new Date(doc.data().completedDate.toDate())).toISOString(),
    };
  }

  static getNextActivityDate(athleteId, params) {
    return http
      .get(`/measurements/athlete/${athleteId}/next-activity-date`, { params })
      .then((response) => response.data);
  }

  static getPreviousActivityDate(athleteId, params) {
    return http
      .get(`/measurements/athlete/${athleteId}/previous-activity-date`, { params })
      .then((response) => response.data);
  }

  static getPreviousExerciseDate(athleteId, params) {
    return http
      .get(`/measurements/athlete/${athleteId}/previous-exercise-activity-date`, { params })
      .then((response) => response.data);
  }

  static getPreviousWellnessDate(athleteId, params) {
    return http
      .get(`/measurements/athlete/${athleteId}/previous-wellness-activity-date`, { params })
      .then((response) => response.data);
  }

  static async getPreviousMeasureDate(athleteId, params) {
    if (params.type === MetadataType.WELLNESS) {
      const req = { athleteId: athleteId, wellnessType: params.id, date: params.date };

      const response = await MeasurementService.getPreviousWellnessDate(athleteId, req);

      return { id: params.id, date: response.date };
    }

    if (params.type === MetadataType.EXERCISE) {
      const req = { athleteId: athleteId, exerciseId: params.id, date: params.date };

      const response = await MeasurementService.getPreviousExerciseDate(athleteId, req);

      return { id: params.id, date: response.date };
    }

    if (params.type === MetadataType.ANTHROPOMETRIC) {
      const req = { date: params.date };

      let response = { id: params.id, date: null };

      if (params.id === "height") {
        const data = await AthleteService.getPreviousHeightDate(athleteId, req);
        response = { id: params.id, date: data.date };
      }

      if (params.id === "weight") {
        const data = await AthleteService.getPreviousWeightDate(athleteId, req);
        response = { id: params.id, date: data.date };
      }

      return response;
    }
  }
}
export default MeasurementService;
