import * as math from "mathjs";

class UnitHelper {
  static units = {
    Second: "Second",
    Metre: "Metre",
    Centimetre: "Centimetre",
    "Metres per Second": "Metres per Second",
    Degree: "Degree",
    "Degrees per Second": "Degrees per Second",
    FeetInches: "FeetInches",
    Kilogram: "Kilogram",
    Newton: "Newton",
    Watt: "Watt",
    Kilojoule: "Kilojoule",
    Joule: "Joule",
    Integer: "Integer",
    Inch: "Inch",
    Percentage: "Percentage",
    "Display Percentage": "Display Percentage",
    Pound: "Pound",
    "Metres per Second Squared": "Metres per Second Squared",
    Gravity: "Gravity",
    "Newtons per Kilogram": "Newtons per Kilogram",
    "Watts per Kilogram": "Watts per Kilogram",
  };

  static notations = {
    Second: "s",
    Metre: "m",
    Centimetre: "cm",
    "Metres per Second": "m/s",
    Degree: "°",
    "Degrees per Second": "°/s",
    Kilogram: "kg",
    Newton: "N",
    Watt: "W",
    Kilojoule: "kJ",
    Joule: "J",
    Integer: "", // No Unit
    Inch: '"',
    Percentage: "%",
    "Display Percentage": "%",
    Pound: "lb",
    Gravity: "G",
    "Newtons per Kilogram": "N/kg",
    "Watts per Kilogram": "W/kg",
  };

  static decimals = {
    Second: 3,
    Metre: 2,
    Centimetre: 1,
    "Metres per Second": 2,
    Degree: 0,
    "Degrees per Second": 1,
    Kilogram: 2,
    Newton: 0,
    Watt: 0,
    Kilojoule: 1,
    Joule: 0,
    Integer: 2, // No Unit
    Inch: 1,
    Pound: 1,
    Percentage: 2,
    "Display Percentage": 1,
    Gravity: 2,
    "Newtons per Kilogram": 2,
    "Watts per Kilogram": 2,
  };

  static format(value, unit, decimalOverride = undefined) {
    const decimal = decimalOverride ?? UnitHelper.decimals[unit] ?? UnitHelper.decimals.Integer;
    const notation = UnitHelper.notations[unit] || UnitHelper.notations.Integer;

    const formattedValue = parseFloat(value).toFixed(decimal);

    if (isNaN(formattedValue)) {
      return value;
    }

    return [parseFloat(formattedValue), notation].join("");
  }

  static _converters = {
    Metre: {
      Centimetre: (value) =>
        math.unit(value, UnitHelper.notations.Metre).to(UnitHelper.notations.Centimetre).toNumber(),
      Inch: (metre) => math.unit(metre, UnitHelper.notations.Metre).to("inch").toNumber(),
      FeetInches: (metre) => {
        const base = math.unit(metre, UnitHelper.notations.Metre).to("inch").toNumber();

        const [feet, inches] = math.unit(Math.round(base), UnitHelper.units.Inch.toLowerCase()).splitUnit(["ft", "in"]);

        return [feet.toNumber().toFixed(0), inches.toNumber().toFixed(0), base];
      },
    },
    FeetInches: {
      Metre: ([feet, inches]) => {
        const mathFt = math.unit(feet, "ft");
        const mathInch = math.unit(Number(inches), "in");
        const base = math.add(mathFt, mathInch).to("m").toNumber();

        return base;
      },
    },
    Centimetre: {
      Metre: (value) => math.unit(value, UnitHelper.notations.Centimetre).to(UnitHelper.notations.Metre).toNumber(),
      FeetInches: (centimetre) => {
        const base = math.unit(centimetre, UnitHelper.notations.Centimetre).to("inch").toNumber();
        const [feet, inches] = math.unit(math.round(base), UnitHelper.units.Inch.toLowerCase()).splitUnit(["ft", "in"]);

        return [feet.toNumber().toFixed(0), inches.toNumber().toFixed(0)];
      },
    },
    Kilogram: {
      Pound: (kg) => math.unit(kg, UnitHelper.notations.Kilogram).to(UnitHelper.notations.Pound).toNumber(),
    },
    Pound: {
      Kilogram: (pound) => math.unit(pound, UnitHelper.notations.Pound).to(UnitHelper.notations.Kilogram).toNumber(),
    },
    Joule: {
      Kilojoule: (joule) => math.unit(joule, UnitHelper.notations.Joule).to(UnitHelper.notations.Kilojoule).toNumber(),
    },
    Inch: {
      Centimetre: (inches) => math.unit(inches, "inch").to(UnitHelper.notations.Centimetre).toNumber(),
      Metre: (inches) => math.unit(inches, "inch").to(UnitHelper.notations.Metre).toNumber(),
    },
    Percentage: {
      "Display Percentage": (percentage) => percentage * 100,
    },
    "Metres per Second Squared": {
      Gravity: (mpss) => math.unit(mpss, "m/s^2").toNumber() / 9.80665,
    },
  };

  static convert(value, from, to) {
    const fromUnit = UnitHelper._converters[from];
    const converter = fromUnit && fromUnit[to];

    if (value === null || isNaN(value) || !converter) {
      return value;
    }

    return converter(value);
  }

  static process(value, { from, to, decimals }) {
    const number = UnitHelper.convert(value, from, to);

    const formatAs = to || from;

    if (to === "FeetInches") {
      const [feet, inches] = number;

      return `${feet}'${inches}"`;
    }

    return UnitHelper.format(number, formatAs, decimals);
  }

  /**
   * @description Format a value with a unit
   *
   * Part of the new implementation that returns an array of results, and a formatted string.
   * Results can be used to display value and unit separately in the UI. For example, to display a value in a larger font size.
   */
  static formatUnit(value, unit, decimalOverride = undefined) {
    const decimal = decimalOverride ?? UnitHelper.decimals[unit] ?? UnitHelper.decimals.Integer;

    let notation = UnitHelper.notations[unit] || UnitHelper.notations.Integer;
    if (unit === "Inch") notation = "in";

    const formattedValue = parseFloat(value).toFixed(decimal);
    if (isNaN(formattedValue)) {
      return {
        results: [
          {
            value: value,
            unit: notation,
            formatted: value,
          },
        ],
        value: value,
        formatted: value,
      };
    }

    const parsed = parseFloat(formattedValue);
    const formatted = `${parsed}${notation}`;

    return {
      results: [
        {
          value: parsed,
          unit: notation,
          formatted: formatted,
        },
      ],
      value: parsed,
      formatted: formatted,
    };
  }

  static getUnitNotation(unit) {
    if (unit === "Inch") return "in";
    return UnitHelper.notations[unit] || UnitHelper.notations.Integer;
  }

  /**
   * @description Convert a value from one unit to another
   *
   * Part of the new implementation that returns an array of results, and a formatted string.
   * Results can be used to display value and unit separately in the UI. For example, to display a value in a larger font size.
   */
  static processUnit(value, { from, to, decimals }) {
    const number = UnitHelper.convert(value, from, to);

    const formatAs = to || from;

    if (formatAs === "FeetInches") {
      const [feet, inches, base] = number;

      return {
        results: [
          {
            value: feet,
            unit: "ft",
            formatted: `${feet}ft`,
          },
          {
            value: inches,
            unit: "in",
            formatted: `${inches}in`,
          },
        ],
        value: base,
        formatted: `${feet}ft ${inches}in`,
      };
    }

    return UnitHelper.formatUnit(number, formatAs, decimals);
  }
}
export default UnitHelper;
