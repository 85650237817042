import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function AthleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C10.7574 6 9.75 7.00736 9.75 8.25C9.75 9.49264 10.7574 10.5 12 10.5C13.2426 10.5 14.25 9.49264 14.25 8.25C14.25 7.00736 13.2426 6 12 6ZM8.25 8.25C8.25 6.17893 9.92893 4.5 12 4.5C14.0711 4.5 15.75 6.17893 15.75 8.25C15.75 10.3211 14.0711 12 12 12C9.92893 12 8.25 10.3211 8.25 8.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.59126 16.004C6.47541 16.6239 6 17.3605 6 18C6 18.4142 5.66421 18.75 5.25 18.75C4.83579 18.75 4.5 18.4142 4.5 18C4.5 16.5684 5.53563 15.43 6.8628 14.6927C8.21537 13.9413 10.0331 13.5 12 13.5C13.9669 13.5 15.7846 13.9413 17.1372 14.6927C18.4644 15.43 19.5 16.5684 19.5 18C19.5 18.4142 19.1642 18.75 18.75 18.75C18.3358 18.75 18 18.4142 18 18C18 17.3605 17.5246 16.6239 16.4087 16.004C15.3183 15.3982 13.761 15 12 15C10.239 15 8.68171 15.3982 7.59126 16.004Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default AthleteIcon;
