import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function SettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.5C11.5029 4.5 11.1 4.90294 11.1 5.4C11.1 7.03425 9.12414 7.85079 7.96955 6.6962C7.61808 6.34473 7.04823 6.34473 6.69676 6.6962C6.34529 7.04767 6.34529 7.61752 6.69676 7.96899C7.85198 9.12422 7.03417 11.1 5.4 11.1C4.90294 11.1 4.5 11.5029 4.5 12C4.5 12.4971 4.90294 12.9 5.4 12.9C7.03459 12.9 7.85159 14.8763 6.6966 16.0313C6.34513 16.3827 6.34513 16.9526 6.6966 17.304C7.04808 17.6555 7.61792 17.6555 7.9694 17.304C9.12364 16.1498 11.1 16.9653 11.1 18.6C11.1 19.0971 11.5029 19.5 12 19.5C12.4971 19.5 12.9 19.0971 12.9 18.6C12.9 16.9669 14.8748 16.147 16.0306 17.3028C16.382 17.6543 16.9519 17.6543 17.3034 17.3028C17.6548 16.9513 17.6548 16.3815 17.3034 16.03C16.1482 14.8748 16.9667 12.9 18.6 12.9C19.0971 12.9 19.5 12.4971 19.5 12C19.5 11.5029 19.0971 11.1 18.6 11.1C16.9672 11.1 16.1478 9.12562 17.3032 7.97024C17.6547 7.61877 17.6547 7.04892 17.3032 6.69745C16.9517 6.34598 16.3819 6.34597 16.0304 6.69745C14.8744 7.85347 12.9 7.03254 12.9 5.4C12.9 4.90294 12.4971 4.5 12 4.5ZM9.6 5.4C9.6 4.07452 10.6745 3 12 3C13.3255 3 14.4 4.07452 14.4 5.4C14.4 5.69851 14.7603 5.84622 14.9698 5.63679C15.907 4.69953 17.4266 4.69953 18.3639 5.63679C19.3011 6.57404 19.3011 8.09364 18.3639 9.0309C18.1543 9.24049 18.3024 9.6 18.6 9.6C19.9255 9.6 21 10.6745 21 12C21 13.3255 19.9255 14.4 18.6 14.4C18.3026 14.4 18.1541 14.7594 18.364 14.9693C19.3013 15.9066 19.3013 17.4262 18.364 18.3635C17.4268 19.3007 15.9072 19.3007 14.9699 18.3635C14.7601 18.1537 14.4 18.3019 14.4 18.6C14.4 19.9255 13.3255 21 12 21C10.6745 21 9.6 19.9255 9.6 18.6C9.6 18.304 9.241 18.1538 9.03006 18.3647C8.0928 19.302 6.5732 19.302 5.63594 18.3647C4.69868 17.4275 4.69869 15.9079 5.63594 14.9706C5.84683 14.7597 5.69703 14.4 5.4 14.4C4.07452 14.4 3 13.3255 3 12C3 10.6745 4.07452 9.6 5.4 9.6C5.69738 9.6 5.84669 9.24024 5.6361 9.02965C4.69884 8.09239 4.69884 6.5728 5.6361 5.63554C6.57336 4.69828 8.09295 4.69828 9.03021 5.63554C9.24085 5.84618 9.6 5.69648 9.6 5.4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75ZM8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default SettingsIcon;
