import { DashboardBase } from "./Dashboard";
import { MetadataType } from "./metadata";

/**
 * Athlete dashboard widget types
 */
export enum DashboardAthleteWidgetType {
  AthleteKpiNumerical = "AthleteKpiNumerical",
  AthleteProgressChart = "AthleteMetricLineChart",
  AthleteTargetGauge = "AthleteTargetGauge",
}

/**
 * Group dashboard widget types
 */
export enum DashboardGroupWidgetType {
  GroupNumericalKpiWidget = "GroupNumericalKpiWidget",
}

export type DashboardWidgetType = DashboardAthleteWidgetType | DashboardGroupWidgetType;

export interface Targets {
  default: number[];
}

export interface DashboardWidgetItemMetadata {
  id: string; //metadata id -> exercise / wellness / anthropometric...
  /**
   * The field name of a metric. Will only be null for anthropometric measures e.g athlete height or weight
   */
  field: string | null; // metric id
  /** No targets, bilateral (.length === 1) or unilateral (.length === 2) targets */
  targets: Targets | null;
  type: MetadataType;
}

export type CalculateBy = StringAutocomplete<"Average" | "Best">;

export interface DashboardWidgetBase {
  id: string;
  type: DashboardWidgetType;
  name: string | null; // Default to something descriptive
  description?: string | null; // Add widget context
  items: DashboardWidgetItemMetadata[];
  calculateBy: CalculateBy;
  groupId: string | null;
  timePeriod: DashboardBase["timePeriod"] | null;
  showPb: boolean | null;
}

export interface AthleteKpiNumerical extends DashboardWidgetBase {
  items: Array<DashboardWidgetItemMetadata>; // Only one item
  type: DashboardAthleteWidgetType.AthleteKpiNumerical;
}

export interface AthleteProgressChart extends DashboardWidgetBase {
  items: Array<DashboardWidgetItemMetadata>;
  type: DashboardAthleteWidgetType.AthleteProgressChart;
}

export interface AthleteTargetGauge extends DashboardWidgetBase {
  items: Array<DashboardWidgetItemMetadata>; // Only one item
  type: DashboardAthleteWidgetType.AthleteTargetGauge;
}

export interface GroupNumericalKpiWidget extends DashboardWidgetBase {
  items: Array<DashboardWidgetItemMetadata>; // Only one item
  type: DashboardGroupWidgetType.GroupNumericalKpiWidget;
}

type AthleteWidget = AthleteKpiNumerical | AthleteProgressChart | AthleteTargetGauge;

// type GroupWidget = ;

export type DashboardWidget = AthleteWidget; // | GroupWidget;
