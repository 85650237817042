import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ExportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10.5C6.41421 10.5 6.75 10.8358 6.75 11.25V19.5H17.25V11.25C17.25 10.8358 17.5858 10.5 18 10.5C18.4142 10.5 18.75 10.8358 18.75 11.25V20.25C18.75 20.6642 18.4142 21 18 21H6C5.58579 21 5.25 20.6642 5.25 20.25V11.25C5.25 10.8358 5.58579 10.5 6 10.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4697 3.96967C11.7626 3.67678 12.2374 3.67678 12.5303 3.96967L16.2803 7.71967C16.5732 8.01256 16.5732 8.48744 16.2803 8.78033C15.9874 9.07322 15.5126 9.07322 15.2197 8.78033L12.75 6.31066V15.75C12.75 16.1642 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1642 11.25 15.75V6.31066L8.78033 8.78033C8.48744 9.07322 8.01256 9.07322 7.71967 8.78033C7.42678 8.48744 7.42678 8.01256 7.71967 7.71967L11.4697 3.96967Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default ExportIcon;
