import { usePostHog } from "posthog-js/react";
import { useUserContext } from "hooks";
import useRole from "providers/RoleProvider/useRole";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { useEffect } from "react";

function PostHogAnalyticsProvider() {
  const posthog = usePostHog();
  const [{ user }] = useUserContext();
  const role = useRole();
  const company = useOrganisation();

  useEffect(() => {
    if (posthog) {
      if (user) {
        posthog.identify(user.uid, {
          email: user.email,
          role: role,
        });
        posthog.group("company", company.name, {
          id: company.id,
        });
      }
    }
  }, [company.id, company.name, posthog, role, user]);

  return null;
}

export default PostHogAnalyticsProvider;
