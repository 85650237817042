import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function LeaderboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 4.5C7.5 4.08579 7.83579 3.75 8.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5V11.25C16.5 13.7353 14.4853 15.75 12 15.75C9.51472 15.75 7.5 13.7353 7.5 11.25V4.5ZM9 5.25V11.25C9 12.9069 10.3431 14.25 12 14.25C13.6569 14.25 15 12.9069 15 11.25V5.25H9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 6.75L7.5 6.75C6.67157 6.75 6 7.42157 6 8.25C6 9.07843 6.67157 9.75 7.5 9.75L8.25 9.75L8.25 11.25L7.5 11.25C5.84315 11.25 4.5 9.90685 4.5 8.25C4.5 6.59315 5.84315 5.25 7.5 5.25L8.25 5.25L8.25 6.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 6.75L16.5 6.75C17.3284 6.75 18 7.42157 18 8.25C18 9.07843 17.3284 9.75 16.5 9.75L15.75 9.75L15.75 11.25L16.5 11.25C18.1569 11.25 19.5 9.90685 19.5 8.25C19.5 6.59315 18.1569 5.25 16.5 5.25L15.75 5.25L15.75 6.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 20.25L11.25 15L12.75 15L12.75 20.25L11.25 20.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 19.5C9 19.0858 9.33579 18.75 9.75 18.75H14.25C14.6642 18.75 15 19.0858 15 19.5C15 19.9142 14.6642 20.25 14.25 20.25H9.75C9.33579 20.25 9 19.9142 9 19.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export default LeaderboardIcon;
