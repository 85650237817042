import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link as RouterLink } from "react-router-dom";
import * as Components from "./DrawerMenuLink.components";

function ListItemLink(props) {
  const { icon, primary, secondary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <Components.DrawerMenuListItem
      button
      component={renderLink}
      selected={props.selected}
      hasSecondary={Boolean(secondary)}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Components.MenuLabel primary={primary} secondary={secondary} />
    </Components.DrawerMenuListItem>
  );
}
export default ListItemLink;
