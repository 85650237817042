import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DumbellIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M3 11H2C1.44772 11 1 11.4477 1 12V12C1 12.5523 1.44772 13 2 13H3" stroke="white" />
      <rect x="3" y="6.5" width="5" height="11" rx="1" stroke="white" fill="transparent" />
      <rect x="8.5" y="11" width="7" height="2" stroke="white" fill="transparent" />
      <rect x="16" y="6.5" width="5" height="11" rx="1" stroke="white" fill="transparent" />
      <path d="M21 11H22C22.5523 11 23 11.4477 23 12V12C23 12.5523 22.5523 13 22 13H21" stroke="white" />
    </SvgIcon>
  );
}
export default DumbellIcon;
