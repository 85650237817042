import { useCallback } from "react";
import useRole from "providers/RoleProvider/useRole";
import CanDoService from "services/CanDoService";
import { CanDoActions } from "../../types/CanDoActions";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useRemoteConfig from "providers/RemoteConfigProvider/useRemoteConfig";

function useCanDo(): (actionName: CanDoActions) => boolean;
function useCanDo(actionName: CanDoActions): boolean;
function useCanDo(actionName?: CanDoActions) {
  const role = useRole();
  const organisation = useOrganisation();
  const HUB_WORKOUTS_ENABLED_ENFORCEMENT = useRemoteConfig("HUB_WORKOUTS_ENABLED_ENFORCEMENT");

  const check = useCallback(
    (action: CanDoActions) => {
      return CanDoService.check(action, {
        role,
        organisation,
        remoteConfig: {
          HUB_WORKOUTS_ENABLED_ENFORCEMENT,
        },
      });
    },
    [role, organisation, HUB_WORKOUTS_ENABLED_ENFORCEMENT]
  );

  if (typeof actionName !== "undefined") return check(actionName);

  return check;
}

export default useCanDo;
